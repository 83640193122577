import axios from 'axios'

class MaterialApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/material/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/material/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getBelongToSupplier(supplier_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/material/get/supplier_id/${supplier_id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async add({ store_material_name, store_material_for_suppliers }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/material/add`, {
        store_material_name,
        store_material_for_suppliers,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit({ store_material_name, store_material_for_suppliers, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/material/edit`, {
        store_material_name,
        store_material_for_suppliers,
        material_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/material/remove/material_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new MaterialApi()

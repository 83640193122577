import axios from 'axios'

class StoreApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/storeSelling/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getGroup({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/getGroup`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async add({
    store_selling_for_suppliers,
    store_selling_for_material,
    store_selling_quantity,
    store_selling_details,
    store_selling_price,
    store_selling_paid_price,
    store_selling_deserve_date,
    store_selling_date,
    store_selling_measure,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/add`, {
        store_selling_for_suppliers,
        store_selling_for_material,
        store_selling_quantity,
        store_selling_details,
        store_selling_price,
        store_selling_paid_price,
        store_selling_deserve_date,
        store_selling_date,
        store_selling_measure,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit({
    store_selling_for_suppliers,
    store_selling_for_material,
    store_selling_quantity,
    store_selling_details,
    store_selling_price,
    store_selling_paid_price,
    store_selling_deserve_date,
    store_selling_date,
    store_selling_measure,
    id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/storeSelling/edit`, {
        store_selling_for_suppliers,
        store_selling_for_material,
        store_selling_quantity,
        store_selling_details,
        store_selling_price,
        store_selling_paid_price,
        store_selling_deserve_date,
        store_selling_date,
        store_selling_measure,
        store_selling_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/storeSelling/remove/store_selling_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new StoreApi()

<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">كلف مواد برسم البيع</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة رصيد مخزني<v-icon right> fa-plus </v-icon>
            </v-btn>

            <v-btn
              tile
              color="#009688"
              class="ml-2 mb-4 mb-md-0 mb-sm-0 white--text"
              :block="isScreenXs"
              @click="$router.push('/store/selling/count')"
            >
              الرصيد المخزني
            </v-btn>
            <!-- <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="goToInvoicePage"> فتح الفاتورة
                <v-icon right> fa-plus </v-icon>
              </v-btn> -->
            <v-btn
              tile
              color="success"
              :loading="xlsxData.downloadLoading"
              :block="isScreenXs"
              @click="getAllDataAxios"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.allPrice="{ item }">
              {{ numberWithComma(item.allPrice) }}
            </template>
            <template v-slot:item.store_selling_paid_price="{ item }">
              {{ numberWithComma(item.store_selling_paid_price) }}
            </template>
            <template v-slot:item.remainingPrice="{ item }">
              {{ numberWithComma(item.remainingPrice) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="addData.store_selling_for_suppliers"
                    :rules="rules.store_selling_for_suppliers"
                    :loading="suppliersLoading"
                    :items="suppliersData"
                    item-text="store_suppliers_name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="المجهز"
                    @click:clear="addData.store_selling_for_suppliers = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="addData.store_selling_for_material"
                    :rules="rules.store_selling_for_material"
                    :loading="materialLoading"
                    :items="materialsData"
                    item-text="store_material_name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="المادة"
                    @click:clear="addData.store_selling_for_material = null"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="addData.store_selling_quantity"
                    label="الكمية"
                    :rules="rules.store_selling_price"
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="addData.store_selling_price"
                    :error-messages="errors.rate"
                    :rules="rules.store_selling_price"
                    dense
                    label="سعر المفرد"
                    outlined
                  />
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="allPrice"
                    :error-messages="errors.rate"
                    dense
                    label="المبلغ الكلي"
                    outlined
                    disabled
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.store_selling_paid_price"
                    :error-messages="errors.rate"
                    :rules="rules.store_selling_paid_price"
                    dense
                    label="المبلغ المدفوع"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="remainingPrice"
                    :error-messages="errors.rate"
                    dense
                    label="المبلغ المتبقي"
                    outlined
                    disabled
                  />
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuDeserveDateAdd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.store_selling_deserve_date"
                        dense
                        label="تاريخ الاستحقاق"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.store_selling_deserve_date" @input="menuDeserveDateAdd = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.store_selling_date"
                        dense
                        label="تاريخ الانشاء"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="addData.store_selling_date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="addData.store_selling_measure" label="القياس" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    rows="3"
                    row-height="15"
                    v-model="addData.store_selling_details"
                    label="الملاحظة"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addStoreSelling"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.store_selling_for_suppliers"
                      :rules="rules.store_selling_for_suppliers"
                      :loading="suppliersLoading"
                      :items="suppliersData"
                      item-text="store_suppliers_name"
                      item-value="_id"
                      clearable
                      outlined
                      dense
                      label="المجهز"
                      @click:clear="editedItem.store_selling_for_suppliers = null"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.store_selling_for_material"
                      :rules="rules.store_selling_for_material"
                      :loading="materialLoading"
                      :items="materialsData"
                      item-text="store_material_name"
                      item-value="_id"
                      clearable
                      outlined
                      dense
                      label="المادة"
                      @click:clear="editedItem.store_selling_for_material = null"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.store_selling_quantity"
                      label="الكمية"
                      :rules="rules.store_selling_price"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-currency-field
                      v-model="editedItem.store_selling_price"
                      :error-messages="errors.rate"
                      :rules="rules.store_selling_price"
                      dense
                      label="سعر المفرد"
                      outlined
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-currency-field
                      v-model="allPriceEdit"
                      :error-messages="errors.rate"
                      dense
                      label="المبلغ الكلي"
                      outlined
                      disabled
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-currency-field
                      v-model="editedItem.store_selling_paid_price"
                      :error-messages="errors.rate"
                      :rules="rules.store_selling_paid_price"
                      dense
                      label="المبلغ المدفوع"
                      outlined
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-currency-field
                      v-model="remainingPriceEdit"
                      :error-messages="errors.rate"
                      dense
                      label="المبلغ المتبقي"
                      outlined
                      disabled
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="menuDeserveDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.store_selling_deserve_date"
                          dense
                          label="تاريخ الاستحقاق"
                          outlined
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.store_selling_deserve_date" @input="menuDeserveDate = false">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="menuDateEdit"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.store_selling_date"
                          dense
                          label="تاريخ الانشاء"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.store_selling_date" @input="menuDateEdit = false">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.store_selling_measure" label="القياس" outlined required dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      rows="3"
                      row-height="15"
                      v-model="editedItem.store_selling_details"
                      label="الملاحظة"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import materialApi from '@/api/material'
import storeApi from '@/api/store'
import suppliersApi from '@/api/suppliers'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'

export default {
  data () {
    return {
      rate: 0,

      errors: {},

      menuDeserveDate: false,

      menuDeserveDateAdd: false,

      menuDate: false,

      menuDateEdit: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      rules: {
        store_selling_for_suppliers: [value => !!value || 'المجهز مطلوب'],
        store_selling_for_material: [value => !!value || 'المادة مطلوبة'],
        store_selling_quantity: [value => !!value || 'الكمية مطلوب'],
        store_selling_price: [value => !!value || 'السعر مطلوب'],
        store_selling_paid_price: [value => !!value || 'السعر المدفوع مطلوب'],
        store_selling_date: [value => !!value || 'السعر المدفوع مطلوب'],
      },

      addData: {
        store_selling_for_suppliers: null,
        store_selling_for_material: null,
        store_selling_quantity: null,
        store_selling_price: null,
        store_selling_paid_price: null,
        store_selling_deserve_date: null,
        store_selling_measure: null,
        store_selling_date: getCurrentDateInString(),
        store_selling_details: null,
      },

      remainingPrice: null,
      remainingPriceEdit: null,

      allPrice: null,
      allPriceEdit: null,

      deleteItemLoading: false,

      content_url: null,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'الرصيد المخزني',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      suppliersLoading: false,

      suppliersData: [],

      materialLoading: false,

      materialsData: [],

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'المجهز', sortable: false, value: 'suppliers_name' },
          { text: 'المادة', sortable: false, value: 'material_name' },
          { text: 'المبلغ الكلي', sortable: false, value: 'allPrice' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'store_selling_paid_price' },
          { text: 'المبلغ المتبقي', sortable: false, value: 'remainingPrice' },
          { text: 'القياس', sortable: false, value: 'store_selling_measure' },
          { text: 'الملاحظات', sortable: false, value: 'store_selling_details' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },

    'addData.store_selling_for_suppliers': {
      handler (value) {
        if (!value) {
          this.materialsData = []
        } else {
          this.getMaterialBelongToSupplier(value)
        }
      },
    },

    'addData.store_selling_quantity': {
      handler () {
        this.allPrice = this.getAllPrice()
        this.remainingPrice = this.getRemainingPrice()
      },
    },

    'addData.store_selling_price': {
      handler () {
        this.allPrice = this.getAllPrice()
        this.remainingPrice = this.getRemainingPrice()
      },
    },

    'addData.store_selling_paid_price': {
      handler () {
        this.allPrice = this.getAllPrice()
        this.remainingPrice = this.getRemainingPrice()
      },
    },

    // forEdit
    'editedItem.store_selling_for_suppliers': {
      handler (value) {
        if (!value) {
          this.materialsData = []
        } else {
          this.getMaterialBelongToSupplier(value)
        }
      },
    },

    'editedItem.store_selling_quantity': {
      handler () {
        this.allPriceEdit = this.getAllPriceEdit()
        this.remainingPriceEdit = this.getRemainingPriceEdit()
      },
    },

    'editedItem.store_selling_price': {
      handler () {
        this.allPriceEdit = this.getAllPriceEdit()
        this.remainingPriceEdit = this.getRemainingPriceEdit()
      },
    },

    'editedItem.store_selling_paid_price': {
      handler () {
        this.allPriceEdit = this.getAllPriceEdit()
        this.remainingPriceEdit = this.getRemainingPriceEdit()
      },
    },
  },

  created () {
    this.getDataAxios()
    this.getSuppliersNameAndId()
  },
  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await storeApi.get({
        search,
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    editItem (item) {
      this.editedItem = { ...item }
      this.getMaterialBelongToSupplier(this.editedItem.store_selling_for_suppliers)
      this.allPriceEdit = this.getAllPriceEdit()
      this.remainingPriceEdit = this.getRemainingPriceEdit()
      this.dialogEdit.open = true
    },

    async editItemConform () {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      const response = await storeApi.edit({
        store_selling_for_suppliers: this.editedItem.store_selling_for_suppliers,
        store_selling_for_material: this.editedItem.store_selling_for_material,
        store_selling_price: this.editedItem.store_selling_price,
        store_selling_paid_price: this.editedItem.store_selling_paid_price,
        store_selling_quantity: this.editedItem.store_selling_quantity,
        store_selling_date: this.editedItem.store_selling_date,
        store_selling_deserve_date: this.editedItem.store_selling_deserve_date,
        store_selling_details: this.editedItem.store_selling_details,
        store_selling_measure: this.editedItem.store_selling_measure,
        id: this.editedItem._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async addStoreSelling () {
      this.addDialog.loading = true

      const response = await storeApi.add({
        store_selling_for_suppliers: this.addData.store_selling_for_suppliers,
        store_selling_for_material: this.addData.store_selling_for_material,
        store_selling_quantity: +this.addData.store_selling_quantity,
        store_selling_price: +this.addData.store_selling_price,
        store_selling_paid_price: +this.addData.store_selling_paid_price,
        store_selling_deserve_date: this.addData.store_selling_deserve_date,
        store_selling_date: this.addData.store_selling_date,
        store_selling_details: this.addData.store_selling_details,
        store_selling_measure: this.addData.store_selling_measure,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.results, 'primary')
        this.getDataAxios()
      }
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await storeApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios () {
      this.xlsxData.downloadLoading = true

      const response = await storeApi.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    handleDownload () {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['المجهز', 'المادة', 'المبلغ الكلي', 'المبلغ المدفوع', 'المبلغ المتبقي', 'الملاحظات']
        const filterVal = [
          'suppliers_name',
          'material_name',
          'allPrice',
          'store_selling_paid_price',
          'remainingPrice',
          'store_selling_details',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    async getSuppliersNameAndId () {
      this.suppliersLoading = true

      const response = await suppliersApi.getSuppliersNameAndId()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.suppliersLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.suppliersLoading = false
        this.suppliersData = response.data.results
      }
    },

    async getMaterialBelongToSupplier (supplier_id) {
      this.materialLoading = true

      const response = await materialApi.getBelongToSupplier(supplier_id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.materialLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.materialLoading = false
        this.materialsData = response.data.results
      }
    },

    getAllPrice () {
      return this.addData.store_selling_quantity * this.addData.store_selling_price
    },

    getRemainingPrice () {
      return (
        this.addData.store_selling_quantity * this.addData.store_selling_price - this.addData.store_selling_paid_price
      )
    },

    getAllPriceEdit () {
      return this.editedItem.store_selling_quantity * this.editedItem.store_selling_price
    },

    getRemainingPriceEdit () {
      return (
        this.editedItem.store_selling_quantity * this.editedItem.store_selling_price -
        this.editedItem.store_selling_paid_price
      )
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,
  },
}
</script>
